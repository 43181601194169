<script>
import {defineComponent} from 'vue'
import TranslationInput from "../elements/translation-input.vue";
import {mapGetters} from "vuex";
import IconInput from "../elements/icon-input.vue";

export default defineComponent({
    name: "priority-form",

    emits: ['saved'],

    props: {
        priority: {
            type: Object,
            required: true
        }
    },

    components: {IconInput, TranslationInput},

    computed: {
        ...mapGetters({
            'loading': 'priority/loading',
            'states': 'priority/states',
            'errors': 'priority/errors'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },
    },

    data: function () {
        return {
            model: {}
        }
    },

    methods: {

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({}, this.model)

            return this.$store.dispatch(`priority/${method}`, data).then(saved => {
                this.setModel(saved)
                this.$emit('saved')
                if (method === 'create') {
                    this.$router.push(this.$t('routes.'+'/priorities/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },

        setModel: function (model = {}) {
            this.model = Object.assign({}, JSON.parse(JSON.stringify(model)))
        },
    },

    watch: {
        priority: function (value) {
            this.setModel(value)
        }
    },

    created() {
        this.setModel(this.priority)
    }
})
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('projects::priorities.columns.name').ucFirst() }}</label
                    >
                    <input
                        id="name"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false}"
                        :placeholder="$t('projects::priorities.placeholders.name').ucFirst()"
                        class="form-control"
                        name="name"
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>

                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.translation_key === false}"
                        class="form-label"
                        for="translation_key"
                    >{{ $t('projects::priorities.columns.translation_key').ucFirst() }}</label
                    >
                    <translation-input
                        v-model="model.translation_key"
                        :error="errors.translation_key"
                        :placeholder="$t('projects::priorities.placeholders.translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                        :state="states.translation_key"
                    ></translation-input>
                    <div :class="{'d-block': states.translation_key === false}" class="invalid-feedback">
                        {{ errors.translation_key }}
                    </div>
                </div>
                <div class="col-lg-3">

                    <label
                        :class="{'text-danger': states.description === false}"
                        class="form-label"
                        for="description"
                    >{{ $t('projects::priorities.columns.description').ucFirst() }}</label
                    >
                    <textarea
                        id="description"
                        v-model="model.description"
                        :class="{'border-danger': states.description === false}"
                        :placeholder="$t('projects::priorities.placeholders.description').ucFirst()"
                        class="form-control"
                        rows="3"
                    ></textarea>
                    <div :class="{'d-block': states.description === false}" class="invalid-feedback">
                        {{ errors.description }}
                    </div>
                </div>
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.description_translation_key === false}"
                        class="form-label"
                        for="description_translation_key"
                    >{{ $t('projects::priorities.columns.description_translation_key').ucFirst() }}</label
                    >
                    <translation-input
                        v-model="model.description_translation_key"
                        :error="errors.description_translation_key"
                        :placeholder="$t('projects::priorities.placeholders.description_translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                        :state="states.description_translation_key"
                    ></translation-input>
                    <div :class="{'d-block': states.description_translation_key === false}" class="invalid-feedback">
                        {{ errors.description_translation_key }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-3">

                    <label
                        :class="{'text-danger': states.short === false}"
                        class="form-label"
                        for="short"
                    >{{ $t('projects::priorities.columns.short').ucFirst() }}</label
                    >
                    <input
                        id="short"
                        v-model="model.short"
                        :class="{'border-danger': states.short === false}"
                        :placeholder="$t('projects::priorities.placeholders.short').ucFirst()"
                        class="form-control"
                        type="text"
                    />
                    <div :class="{'d-block': states.short === false}" class="invalid-feedback">
                        {{ errors.short }}
                    </div>
                </div>
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.short_translation_key === false}"
                        class="form-label"
                        for="short_translation_key"
                    >{{ $t('projects::priorities.columns.short_translation_key').ucFirst() }}</label
                    >
                    <translation-input
                        v-model="model.short_translation_key"
                        :error="errors.short_translation_key"
                        :placeholder="$t('projects::priorities.placeholders.short_translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                        :state="states.short_translation_key"
                    ></translation-input>
                    <div :class="{'d-block': states.short_translation_key === false}" class="invalid-feedback">
                        {{ errors.short_translation_key }}
                    </div>
                </div>
                <div class="col-lg-1">
                    <label
                        :class="{'text-danger': states.color === false}"
                        class="form-label"
                        for="color"
                    >{{ $t('projects::priorities.columns.color').ucFirst() }}</label
                    >
                    <input
                        id="color"
                        v-model="model.color"
                        :class="{'border-danger': states.color === false}"
                        :placeholder="$t('projects::priorities.placeholders.color').ucFirst()"
                        class="form-control form-control-color"
                        name="color"
                        type="color"
                    />
                    <div :class="{'d-block': states.color === false}" class="invalid-feedback">{{ errors.color }}</div>
                </div>
                <div class="col-lg-1">
                    <icon-input
                        id="icon"
                        v-model="model.icon"
                        :class="{'border-danger': states.icon === false}"
                        :placeholder="$t('projects::priorities.placeholders.icon').ucFirst()"
                        class="form-control"
                        name="icon"
                        :state="states.icon"
                        :error="errors.icon"
                    >{{ $t('projects::priorities.columns.icon').ucFirst() }}</icon-input>
                </div>
                <div class="col-lg-1">
                    <label
                        :class="{'text-danger': states.is_active === false}"
                        class="form-label"
                        for="is-active-input"
                    >{{ $t('projects::priorities.columns.is_active').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md">
                        <input
                            id="is-active-input"
                            v-model="model.is_active"
                            :class="{'border-danger': states.is_active === false}"
                            class="form-check-input"
                            name="is_active"
                            type="checkbox"
                        />
                    </div>
                    <div :class="{'d-block': states.is_active === false}" class="invalid-feedback">
                        {{ errors.is_active }}
                    </div>
                </div>
            </div>

            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
